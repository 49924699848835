<template>
  <div>
    <BModal
      id="modal-verification"
      ref="modal-verification"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('modal-verification')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Verifikasi No HP
        </h4>
      </BCol>
      <BCol class="d-flex justify-content-center mt-1">
        <strong class="text-center">Mohon verifikasi identitas Kamu dengan memasukan Kode Verifikasi (OTP) yang dikirmkan Via SMS ke Nomor
          <br>
          0{{ phoneNumber }}
        </strong>
      </BCol>
      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          :loading="false"
          class="input"
          :type="visibleOtp"
          @change="onChange"
        />
      </BCol>
      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibleOtp"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>
      <BCol class="d-flex justify-content-center mt-1 text-center">
        <span v-if="countOtp > 0">
          Mohon tunggu {{ countOtp }}. detik untuk mengirim ulang.
        </span>

        <BButton
          v-else
          variant="flat-primary"
          size="sm"
          class="btn-icon"
          :disabled="loading"
          @click="sendOtp"
        >
          Kirim Ulang
        </BButton>
      </BCol>
      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          variant="primary"
          :disabled="otp === '' || otp.length < 6"
          @click="onFinish"
        >
          <BSpinner
            v-if="loadingSubmit"
            small
            variant="light"
          />
          Konfirmasi
        </BButton>
      </BCol>
    </BModal>
    <BModal
      id="modal-success"
      ref="modal-success"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      centered
      hide-header
    >
      <ModalSuccess />
    </BModal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import provider from '@/provider/provider'
import { OTP_VERIFICATION, STORE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT } from '@/provider/url'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import CodeInput from 'vue-verification-code-input'
import secureLocalStorage from '@/libs/secureLocalstorage'
import isEmpty from 'lodash/isEmpty'
import ModalSuccess from './ModalSuccess.vue'

export default {
  components: {
    BModal, BRow, BCol, CodeInput, ModalSuccess, BSpinner,
  },
  props: {
    sendOtp: {
      type: Function,
      required: true,
    },
    countOtp: {
      type: Number,
      default: () => 0,
    },
    expiredOtp: {
      type: Number,
      default: () => 0,
    },
    source: {
      type: String,
      default: () => '',
    },
    rekeningNumber: {
      type: String,
      default: () => '',
    },
    rekeningName: {
      type: String,
      default: () => '',
    },
    bankName: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userId: this?.$store?.state?.auth?.userData?.id,
      phoneNumber: this?.$store?.state?.auth?.userData?.mitras?.phone_number,
      otp: '',
      loadingSubmit: false,
      visibleOtp: 'number',
      isVisibility: true,
      bank: secureLocalStorage.get('rekening'),
    }
  },
  methods: {
    async onFinish() {
      this.loadingSubmit = true
      const data = new FormData()
      const dataBody = [
        {
          name: 'otp',
          value: this.otp,
        },
        {
          name: 'session',
          value: 'rekening',
        },
      ]

      dataBody.map(item => data.append(item.name, item.value))
      await provider
        .insert({
          url: OTP_VERIFICATION,
          body: data,
        })
        .then(async () => {
          if (this.source === 'add') {
            // eslint-disable-next-line no-shadow
            const data = new FormData()
            // eslint-disable-next-line no-shadow
            const dataBody = [
              {
                name: 'user_id',
                value: this.userId,
              },
              {
                name: 'bank_name',
                value: this.bankName,
              },
              {
                name: 'bank_owner_name',
                value: this.rekeningName,
              },
              {
                name: 'bank_no',
                value: Number(this.rekeningNumber),
              },
            ]

            dataBody.map(item => data.append(item.name, item.value))
            await provider.insert({
              url: STORE_BANK_ACCOUNT,
              body: data,
            })
              .then(() => {
                this.loadingSubmit = false
                this.$refs['modal-verification'].hide()
                this.$refs['modal-success'].show()
              })
              .catch(() => {
                this.loadingSubmit = false
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Gagal',
                    icon: 'AlertCircleIcon',
                    text: 'Maaf, nomor rekening yang anda inputkan sudah terdaftar',
                    variant: 'danger',
                  },
                }, 2000)
              })
          }
          if (this.source === 'edit') {
            const payload = {
              id: this.bank.id,
              user_id: this.userId,
              bank_name: isEmpty(this.bankName) ? this.bank.bank_name : this.bankName,
              bank_owner_name: isEmpty(this.rekeningName) ? this.bank.bank_owner_name : this.rekeningName,
              bank_no: isEmpty(this.rekeningNumber) ? Number(this.bank.bank_no) : Number(this.rekeningNumber),
            }
            await provider.update(UPDATE_BANK_ACCOUNT, payload)
              .then(() => {
                this.loadingSubmit = false
                this.$refs['modal-verification'].hide()
                this.$refs['modal-success'].show()
              })
              .catch(() => {
                this.loadingSubmit = false
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Gagal',
                    icon: 'AlertCircleIcon',
                    text: 'Maaf, anda Gagal mengubah rekening bank',
                    variant: 'danger',
                  },
                }, 2000)
              })
          }
        })
        .catch(() => {
          this.loadingSubmit = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'OTP yang anda masukan salah',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    onChange(otp) {
      this.otp = otp
    },
    toggleVisibleOtp() {
      this.isVisibility = !this.isVisibility
    },
  },

}
</script>
