<template>
  <div>
    <BCol
      md="12"
      class="d-flex justify-content-center pt-3"
    >
      <b-img
        width="100"
        src="@core/assets/image/icon-popup-success.png"
      />
    </BCol>
    <BCol class="text-center mt-2">
      <h4 class="text-black text-xl">
        <strong>Verifikasi No HP Berhasil</strong>
      </h4>
    </BCol>
    <div class="text-center my-2">
      <BButton
        variant="primary"
        @click="closeModal"
      >
        Kembali
      </BButton>
    </div>
  </div>
</template>

<script>
import { BCol, BButton } from 'bootstrap-vue'

export default {
  components: { BCol, BButton },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-verification')
      this.$bvModal.hide('modal-success')
      this.$router.push({ path: '/rekening-bank' })
    },
  },
}
</script>
